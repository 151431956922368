import React, { useState, useEffect } from 'react';

const Homepage = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <img src="path/to/your-image.jpg" alt="Your Description" style={{ width: '100%', maxWidth: '300px' }} />
      <p>Here’s some detail about your app or website.</p>
      <button onClick={handleInstallClick} disabled={!deferredPrompt}>
        Install App
      </button>
      <button onClick={() => window.location.href = 'https://your-affiliate-website.com'}>
        Go to Website
      </button>
    </div>
  );
};

export default Homepage;

import React, { useEffect } from 'react';

const Redirect = () => {
  useEffect(() => {
    // Replace the URL with your affiliate casino website URL
    window.location.replace('https://www.oke888.co/?referral=jj39491');
  }, []);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
};

export default Redirect;
